import React from 'react';
import SbEditable from 'storyblok-react';
import LongformTextModule from '.';
import { BlokComponent } from '../../../types/blok.model';
import StoryblokRichText, {
  RichTextDocument,
} from '../../components/RichText/StoryblokRichText';

interface LongformTextModuleBlokProps {
  content: RichTextDocument;
  useNewTypography?: boolean;
}

const LongformTextModuleBlok: BlokComponent<LongformTextModuleBlokProps> = ({
  blok,
  Dynamic,
}) => {
  return (
    <SbEditable content={blok}>
      <LongformTextModule
        useNewTypography={blok.useNewTypography}
        content={<StoryblokRichText content={blok.content} Dynamic={Dynamic} />}
      />
    </SbEditable>
  );
};

export default LongformTextModuleBlok;
