import styled from 'styled-components';
import { ResponsiveImage } from '../../components/ResponsiveImage';
import CMSLink from '../../components/CMSLink';
import { typeScale } from '../../../styles/newTypography';
import { spacing } from '../../../styles/newThemeVariables.styles';
import { breakpointUp } from '../../../styles/mediaUtils';
import { ListItem } from '../../modules/CardList/CardList.styles';

export const Image = styled(ResponsiveImage)<{
  $aspectMobile?: string;
  $aspectTablet?: string;
  $aspectDesktop?: string;
  $aspectDesktopLarge?: string;
}>`
  grid-column: 1 / -1;
  aspect-ratio: ${({ $aspectMobile }) => $aspectMobile ?? '3 / 4'};

  & > div {
    height: 100%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media ${breakpointUp.tablet} {
    aspect-ratio: ${({ $aspectTablet }) => $aspectTablet ?? '3 / 4'};
  }

  @media ${breakpointUp.desktop} {
    aspect-ratio: ${({ $aspectDesktop }) => $aspectDesktop ?? '3 / 4'};
  }

  @media ${breakpointUp.desktopLarge} {
    aspect-ratio: ${({ $aspectDesktopLarge }) =>
      $aspectDesktopLarge ?? '4 / 3'};
  }
`;

export const ClientName = styled.div`
  grid-column: 1 / -1;
  ${typeScale.desktop.mono.md};
  margin-bottom: ${spacing.space16};
  height: calc(${spacing.space24} + 2px);
  display: flex;
  align-items: center;
  justify-content: start;

  @media ${breakpointUp.tablet} {
    margin-bottom: ${spacing.space24};
  }
`;

export const ClientLogo = styled(ResponsiveImage)`
  grid-column: 1 / -1;
  margin-bottom: ${spacing.space16};
  height: calc(${spacing.space24} + 2px);
  display: flex;
  align-items: center;
  justify-content: start;

  img {
    width: auto;
    height: calc(${spacing.space24} + 2px);
  }

  @media ${breakpointUp.tablet} {
    margin-bottom: ${spacing.space24};
  }
`;

export const Link = styled(CMSLink)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${spacing.space16};
  grid-column: 1 / -1;
  cursor: pointer;
  margin-bottom: ${spacing.space32};

  ${Image} {
    opacity: 1;
    transition: opacity 300ms ease-out;
  }

  &:hover {
    ${Image} {
      opacity: 0.85;
    }
  }

  @media ${breakpointUp.tablet} {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: ${spacing.space16};
    margin-bottom: ${spacing.space32};
  }

  @media ${breakpointUp.desktop} {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: ${spacing.space24};
    margin-bottom: ${spacing.space32};
  }

  @media ${breakpointUp.desktopLarge} {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: ${spacing.space24};
    margin-bottom: ${spacing.space32};
  }
`;

export const Details = styled(Link)`
  align-content: start;
  margin-bottom: 0;

  @media ${breakpointUp.tablet} {
    margin-bottom: 0;
  }

  @media ${breakpointUp.desktop} {
    margin-bottom: 0;
  }
`;

export const Title = styled.h2`
  grid-column: 1 / -1;
  ${typeScale.mobile.body.lg};
  margin: 0 0 ${spacing.space12};

  @media ${breakpointUp.tablet} {
    ${typeScale.desktop.body.xl};
    grid-column: span 7;
    margin-bottom: ${spacing.space24};
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.xl};
    margin-bottom: ${spacing.space24};
    grid-column: span 3;
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.xxl};
    grid-column: span 3;
  }
`;

export const Card = styled(ListItem)`
  @media ${breakpointUp.tablet} {
    .desktop-one-column & {
      ${Title} {
        grid-column: span 7;
      }
    }

    .tablet-two-column-highlight & {
      ${Image} {
        aspect-ratio: 4 / 3;
      }

      ${Link} {
        margin-bottom: ${spacing.space16};
      }

      ${Details} {
        margin-bottom: 0;
      }

      ${Title} {
        ${typeScale.desktop.body.md};
      }

      :first-child {
        grid-column: span 2;
      }
    }
  }

  @media ${breakpointUp.desktop} {
    .desktop-two-column & {
      ${Link} {
        grid-template-columns: repeat(6, 1fr);
      }

      ${Title} {
        grid-column: span 4;
      }
    }

    .desktop-two-column-highlight & {
      :first-child {
        grid-column: span 2;

        ${Image} {
          aspect-ratio: 21 / 9;
        }
      }
    }

    .desktop-four-column-highlight & {
      display: grid;
      grid-template-rows: 1fr auto;
      height: 100%;

      ${Link} {
        margin-bottom: ${spacing.space16};
      }

      ${Details} {
        min-height: calc(${spacing.space56} + ${spacing.space88});
        margin-bottom: 0;
      }

      ${Image} {
        height: 100%;
      }

      ${Title} {
        ${typeScale.desktop.body.md};
      }

      :first-child {
        grid-column: span 2;
        grid-row: span 2;

        ${Image} {
          aspect-ratio: auto;
          height: 100%;
        }
      }
    }
  }

  @media ${breakpointUp.desktopLarge} {
    .desktop-large-two-column & {
      ${Link} {
        grid-template-columns: repeat(6, 1fr);
      }

      ${Title} {
        grid-column: span 4;
      }
    }

    .desktop-large-two-column-highlight & {
      :first-child {
        grid-column: span 2;

        ${Image} {
          aspect-ratio: 21 / 9;
        }
      }
    }

    .desktop-large-four-column-highlight & {
      display: grid;
      grid-template-rows: 1fr auto;
      height: 100%;

      ${Link} {
        margin-bottom: ${spacing.space16};
      }

      ${Details} {
        min-height: calc(${spacing.space56} + ${spacing.space112});
        margin-bottom: 0;
      }

      ${Image} {
        height: 100%;
      }

      ${Title} {
        ${typeScale.desktop.body.xl};
      }

      :first-child {
        grid-column: span 2;
        grid-row: span 2;

        ${Image} {
          aspect-ratio: auto;
          height: 100%;
        }
      }
    }
  }
`;
