import React, { FC } from 'react';
import { Layout } from '../../layout/Layout';
import { Grid } from '../../layout/Grid';
import { OldModule, NewModule } from './LongformTextModule.styles';

export interface LongformTextModuleProps {
  content: React.ReactNode;
  useNewTypography?: boolean;
}

const LongformTextModule: FC<LongformTextModuleProps> = ({
  content,
  useNewTypography = false,
}) => {
  const Content = useNewTypography ? NewModule : OldModule;

  return (
    <Layout variant="smallWidth">
      <Grid>
        <Content>{content}</Content>
      </Grid>
    </Layout>
  );
};

export default LongformTextModule;
