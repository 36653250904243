import React from 'react';
import SbEditable, { SbEditableContent } from 'storyblok-react';
import {
  BlokComponent,
  NewStoryblokMetaData,
  StoryblokRelatedPosts,
} from '../../types/blok.model';
import { useNavTheme } from '../../old/components/Nav/NavThemeSetter';
import defaultTheme, { darkTheme } from '../../styles/themeVariables';
import RenderAfterPageTransition from '../../old/components/RenderAfterPageTransition';
import styled, { ThemeProvider } from 'styled-components';
import { ThemeModeContextProvider } from '../../util/useThemeMode';

import { spacing } from '../../styles/newThemeVariables.styles';
import { useCaseStudyThumbnails } from '../../util/useCaseStudyThumbnails';
import { transformCategorySlugs } from '../../util/transformCollection';
import isEmpty from '../../util/isEmpty';

import CardList from '../modules/CardList';
import CardCaseStudy from '../blocks/CardCaseStudy';
import ContactFormModule from '../modules/ContactFormModule';
import { breakpointUp } from '../../styles/mediaUtils';

interface CaseStudySchema extends NewStoryblokMetaData {
  title: string;
  head?: SbEditableContent[];
  body?: SbEditableContent[];
  bodyLower?: SbEditableContent[];
  lightHeader: boolean;
  related_work?: StoryblokRelatedPosts;
}

const Background = styled.div`
  background: ${({ theme }) => theme.bgPrimary};
`;

const ListContainer = styled.div`
  padding-top: ${spacing.space72};
  padding-bottom: ${spacing.space72};

  @media ${breakpointUp.tablet} {
    padding-top: ${spacing.space64};
    padding-bottom: ${spacing.space64};
  }

  @media ${breakpointUp.desktop} {
    padding-top: ${spacing.space112};
    padding-bottom: ${spacing.space112};
  }
`;

const CaseStudyPage: BlokComponent<CaseStudySchema> = ({ blok, Dynamic }) => {
  useNavTheme(blok.lightHeader ? defaultTheme : darkTheme(defaultTheme));

  const relatedPosts = transformCategorySlugs(
    useCaseStudyThumbnails(blok?.related_work ?? [])
  );

  return (
    <SbEditable content={blok}>
      <div>
        {blok.head ? (
          blok.head.map((insideBlok) => (
            <Dynamic key={insideBlok._uid} blok={insideBlok} />
          ))
        ) : (
          <></>
        )}

        <ThemeModeContextProvider themeMode={'light'}>
          <ThemeProvider theme={defaultTheme}>
            <Background>
              <RenderAfterPageTransition>
                {blok.body?.map((insideBlok) => (
                  <Dynamic key={insideBlok._uid} blok={insideBlok} />
                ))}
              </RenderAfterPageTransition>
            </Background>
          </ThemeProvider>
        </ThemeModeContextProvider>

        <ThemeModeContextProvider themeMode={'dark'}>
          <ThemeProvider theme={darkTheme(defaultTheme)}>
            <Background>
              <RenderAfterPageTransition>
                {blok.bodyLower?.map((insideBlok) => (
                  <Dynamic key={insideBlok._uid} blok={insideBlok} />
                ))}
                {relatedPosts && !isEmpty(relatedPosts) && (
                  <ListContainer>
                    <CardList
                      scrollMobile={false}
                      title="Related Case Studies"
                      ctaText="See All"
                      ctaLink="/work/"
                      mobile={{
                        rowGap: spacing.space64,
                        variant: 'one-column',
                      }}
                      tablet={{
                        rowGap: spacing.space64,
                        variant: 'one-column',
                      }}
                      desktop={{
                        rowGap: spacing.space112,
                        variant: 'two-column',
                      }}
                      desktopLarge={{
                        rowGap: spacing.space112,
                        variant: 'two-column',
                      }}
                    >
                      {relatedPosts.map((caseStudy) => (
                        <CardCaseStudy
                          key={caseStudy.id}
                          title={caseStudy.title}
                          link={caseStudy.link}
                          clientLogo={caseStudy.logo}
                          clientLogoDark={caseStudy.logoDark}
                          clientName={caseStudy.client}
                          clientLogoAlt={caseStudy.client}
                          mobile={{
                            aspect: '3 / 4',
                            thumbnail: caseStudy.thumbnail_3x4,
                          }}
                          tablet={{
                            aspect: '4 / 3',
                            thumbnail: caseStudy.thumbnail_4x3,
                          }}
                          desktop={{
                            aspect: '4 / 3',
                            thumbnail: caseStudy.thumbnail_4x3,
                          }}
                          desktopLarge={{
                            aspect: '4 / 3',
                            thumbnail: caseStudy.thumbnail_4x3,
                          }}
                          thumbnailAlt={caseStudy.thumbnail_alt}
                          tags={[...caseStudy.tags, ...caseStudy.categories]}
                        />
                      ))}
                    </CardList>
                  </ListContainer>
                )}
                <ContactFormModule
                  form_data={'7dfec556-192c-483f-bc39-246e44bb1f7b'}
                  tagline={'READY TO GET STARTED?'}
                  title={'Transform how your most important work is delivered'}
                  subtitle={
                    'Meet the team, discuss your ideas with our experts and receive a proposal for your project.'
                  }
                />
              </RenderAfterPageTransition>
            </Background>
          </ThemeProvider>
        </ThemeModeContextProvider>
      </div>
    </SbEditable>
  );
};

export default React.memo(CaseStudyPage);
