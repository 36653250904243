import React, { FC } from 'react';
import styled from 'styled-components';

export const Icon = styled.svg`
  & path {
    fill: ${({ theme }) => theme.textPrimary};
  }
`;

const TickIcon: FC = () => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.16675 6.43977L2.87508 5.77774L5.70841 8.42588L10.6667 3.79163L11.3751 4.45366L5.70841 9.74996L2.16675 6.43977Z"
        fill="#161314"
      />
    </Icon>
  );
};

export default TickIcon;
