import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import Picture from '../../../../old/components/Picture';
import { breakpointUp } from '../../../../styles/mediaUtils';

export type AvatarSizes = 'xs' | 'sm' | 'md' | 'lg';

export const avatarSizes: Record<AvatarSizes, FlattenSimpleInterpolation> = {
  xs: css`
    width: 2.5rem;
    height: 2.5rem;
  `,
  sm: css`
    width: 3rem;
    height: 3rem;
  `,
  md: css`
    width: 4rem;
    height: 4rem;
  `,
  lg: css`
    width: 5rem;
    height: 5rem;
  `,
};

export const StyledPicture = styled(Picture)`
  vertical-align: middle;
  border-radius: 50%;
  overflow: hidden;
  ${avatarSizes.xs}

  @media ${breakpointUp.sm} {
    ${avatarSizes.sm}
  }

  && div {
    ${avatarSizes.xs}

    @media ${breakpointUp.sm} {
      ${avatarSizes.sm}
    }
  }

  && img {
    ${avatarSizes.xs}

    @media ${breakpointUp.sm} {
      ${avatarSizes.sm}
    }
  }
`;
