import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import { textSizes } from '../../../styles/typography';
import { ResponsiveImage } from '../../components/ResponsiveImage';
import Sectors from '../../components/Sectors';
import { typeScale } from '../../../styles/newTypography';

export const StylHeroWrapper = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
  aspect-ratio: 3 / 4;

  @media ${breakpointUp.sm} {
    aspect-ratio: 4 / 3;
  }

  @media ${breakpointUp.md} {
    aspect-ratio: auto;
    height: 83vh;
    max-height: 71.875rem;
    width: 100%;
  }
`;

export const StylHeroImage = styled(ResponsiveImage)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`;

export const StylHeroTags = styled(Sectors)<{
  underlineColor?: string;
  fontColor?: string;
}>`
  margin-bottom: 3rem; /* to prevent being too close to title */

  & > li {
    ${textSizes.xs};
    color: ${({ theme }) => theme.fg};
    border-bottom: none;
    position: relative;
    opacity: 0.8;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -0.125rem;
      width: 100%;
      background-color: ${({ theme }) => theme.white25};
      height: 0.125rem;
    }

    &:hover {
      &::after {
        background-color: ${({ theme }) => theme.white};
      }
    }

    @media ${breakpointUp.md} {
      ${textSizes.xxs};
    }
  }
`;

export const StyledRow = styled.div`
  grid-column: span 2;

  @media ${breakpointUp.sm} {
    grid-column: 1 / -1;
  }

  @media ${breakpointUp.md} {
    grid-column: span 6;
    padding-bottom: 0.5625rem;
  }
`;

export const Title = styled.h1`
  ${typeScale.mobile.body.xxxl}
  color: var(--fg1, ${({ theme }) => theme.fg});
  white-space: break-spaces;
  text-wrap: balance;
  margin: 0;

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.body.xxxxl}
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.fivexl}
  }
`;

export const HeaderFooter = styled.div`
  grid-column: span 2;
  padding: 1.5rem 0;

  @media ${breakpointUp.sm} {
    grid-column: span 12;
    padding: 2rem 0 1.5rem 0;
  }

  @media ${breakpointUp.md} {
    grid-column: 1 / -1;
    padding: 3rem 0;
  }
`;
