import React, { FC } from 'react';
import { StyledBgImage, StyledGrid } from './CallOut.styles';
import {
  ResponsiveVideo,
  VideoAssetProps,
} from '../../components/ResponsiveVideo';
import TextBlock, { TextBlokProps } from './TextBlock';
import { ThemeProvider } from 'styled-components';
import defaultTheme, { darkTheme } from '../../../styles/themeVariables';
import { Section } from '../../layout/Section';
import { Layout } from '../../layout/Layout';

export interface CallOutModuleProps extends TextBlokProps {
  darkMode?: boolean;
  title?: string;
  description?: React.ReactNode;
  items?: {
    type: string;
    id?: string;
    sources: {
      mobile: VideoAssetProps;
      tablet: VideoAssetProps;
      desktop: VideoAssetProps;
    };
  }[];
}

export const CallOutModule: FC<CallOutModuleProps> = ({
  darkMode = false,
  items,
  ...rest
}) => {
  return (
    <ThemeProvider theme={darkMode ? darkTheme(defaultTheme) : defaultTheme}>
      <Section>
        <Layout>
          <StyledGrid>
            {items?.map(({ type, sources, id }, index) => (
              <React.Fragment key={id ?? index}>
                {type === 'videoAsset' ? (
                  <ResponsiveVideo
                    mobile={sources.mobile}
                    tablet={sources.tablet}
                    desktop={sources.desktop}
                  />
                ) : (
                  <StyledBgImage
                    alt={
                      sources.mobile.alt ??
                      sources.tablet.alt ??
                      sources.desktop.alt ??
                      ''
                    }
                    mobile={sources.mobile.filename}
                    tablet={sources.tablet.filename}
                    desktop={sources.desktop.filename}
                    title={
                      sources.mobile.title ??
                      sources.tablet.title ??
                      sources.desktop.title
                    }
                  />
                )}
              </React.Fragment>
            ))}
            <TextBlock {...rest} />
          </StyledGrid>
        </Layout>
      </Section>
    </ThemeProvider>
  );
};

export default CallOutModule;
