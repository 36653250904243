import styled from 'styled-components';
import { typeScale } from '../../../../styles/newTypography';
import { spacing } from '../../../../styles/newThemeVariables.styles';

export const LabelButton = styled.button`
  ${typeScale.desktop.mono.md};
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  color: ${({ theme }) => theme.textPrimary};
  background-color: transparent;
  padding: ${spacing.space4} ${spacing.space8};
  margin: 0 -${spacing.space8};
  border: 0.09375rem solid transparent;

  &::after {
    content: '';
    border-bottom: 0.09375rem solid
      ${({ theme }) => theme.border.BorderUnderline};
    position: absolute;
    bottom: 0;
    left: ${spacing.space8};
    height: 0;
    width: calc(100% - ${spacing.space16});
  }

  &:hover::after {
    content: '';
    border-bottom: 0.09375rem solid
      ${({ theme }) => theme.border.BorderUnderlineHover};
    position: absolute;
    bottom: 0;
    left: ${spacing.space8};
    height: 0;
    width: calc(100% - ${spacing.space16});
  }

  &:active::after {
    content: '';
    border-bottom: 0.09375rem solid
      ${({ theme }) => theme.border.BorderUnderlineActive};
    position: absolute;
    bottom: 0;
    left: ${spacing.space8};
    height: 0;
    width: calc(100% - ${spacing.space16});
  }

  &:focus {
    border: 0.09375rem solid ${({ theme }) => theme.border.BorderFocus};
  }

  &:focus::after {
    content: '';
    border: none;
    position: absolute;
    bottom: 0;
    left: ${spacing.space8};
    height: 0;
    width: calc(100% - ${spacing.space16});
  }
`;

export default LabelButton;
