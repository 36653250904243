import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import { typeScale } from '../../../styles/newTypography';
import { QuoteText } from '../../blocks/QuoteBlock/QuoteBlock.styles';
import { StyledWrapper } from '../ArticleContentModule/ArticleContentModule.styles';

export const NewModule = styled('div')`
  color: ${({ theme }) => theme.greyMedium};
  grid-column: 1 / -1;
  padding-top: 3rem;
  padding-bottom: 3rem;
  ${typeScale.mobile.body.xs};

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.body.md}
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.md}
    grid-column: 3 / span 6;
  }

  & > p {
    ${typeScale.mobile.body.xs}
    display: inline-block;
    margin: 0 0 1.5rem;
    width: 100%;

    @media ${breakpointUp.tablet} {
      ${typeScale.tablet.body.sm}
      margin: 0 0 2rem;
    }

    @media ${breakpointUp.desktop} {
      ${typeScale.desktop.body.md}
      margin: 0 0 3rem;
    }
  }

  picture + p {
    ${typeScale.mobile.body.xs}
  }

  ul > li > p {
    margin: 0;
  }

  p:empty {
    min-height: 1em;
    margin: 0;
  }

  picture > img {
    display: block;
    max-width: 100%;
    max-height: 80vh;
  }

  & > p:nth-of-type(1) {
    color: ${({ theme }) => theme.textPrimary};
    ${typeScale.mobile.body.sm}

    @media ${breakpointUp.tablet} {
      ${typeScale.tablet.body.md}
    }

    @media ${breakpointUp.desktop} {
      ${typeScale.desktop.body.lg}
    }
  }

  p:last-child {
    margin: 0;
  }

  h1 {
    color: ${({ theme }) => theme.textPrimary};
    ${typeScale.mobile.body.xxxxl}

    @media ${breakpointUp.tablet} {
      ${typeScale.tablet.body.xxxxl}
    }

    @media ${breakpointUp.desktop} {
      ${typeScale.desktop.body.xxxxl}
    }

    @media ${breakpointUp.desktopLarge} {
      ${typeScale.desktop.body.fivexl}
    }
  }

  h2 {
    color: ${({ theme }) => theme.textPrimary};
    ${typeScale.mobile.body.xxxl}

    @media ${breakpointUp.tablet} {
      ${typeScale.tablet.body.xxxl}
    }

    @media ${breakpointUp.desktop} {
      ${typeScale.desktop.body.xxxl}
    }

    @media ${breakpointUp.desktopLarge} {
      ${typeScale.desktop.body.xxxxl}
    }
  }

  h3 {
    color: ${({ theme }) => theme.textPrimary};
    ${typeScale.mobile.body.xxl}

    @media ${breakpointUp.tablet} {
      ${typeScale.tablet.body.xxl}
    }

    @media ${breakpointUp.desktop} {
      ${typeScale.desktop.body.xxl}
    }

    @media ${breakpointUp.desktopLarge} {
      ${typeScale.desktop.body.xxxl}
    }
  }

  h4 {
    color: ${({ theme }) => theme.textPrimary};
    ${typeScale.mobile.body.xl}

    @media ${breakpointUp.tablet} {
      ${typeScale.tablet.body.xl}
    }

    @media ${breakpointUp.desktop} {
      ${typeScale.desktop.body.xl}
    }

    @media ${breakpointUp.desktopLarge} {
      ${typeScale.desktop.body.xxl}
    }
  }

  h5 {
    color: ${({ theme }) => theme.textPrimary};
    ${typeScale.mobile.body.lg}

    @media ${breakpointUp.tablet} {
      ${typeScale.tablet.body.lg}
    }

    @media ${breakpointUp.desktop} {
      ${typeScale.desktop.body.lg}
    }

    @media ${breakpointUp.desktopLarge} {
      ${typeScale.desktop.body.xl}
    }
  }

  h6 {
    color: ${({ theme }) => theme.textPrimary};
    ${typeScale.mobile.body.md}

    @media ${breakpointUp.tablet} {
      ${typeScale.tablet.body.md}
    }

    @media ${breakpointUp.desktop} {
      ${typeScale.desktop.body.md}
    }

    @media ${breakpointUp.desktopLarge} {
      ${typeScale.desktop.body.lg}
    }
  }

  ol,
  ul {
    margin: 0 0 1.5rem;

    @media ${breakpointUp.tablet} {
      ${typeScale.tablet.body.sm};
      margin: 0 0 2rem;
    }

    @media ${breakpointUp.desktop} {
      ${typeScale.desktop.body.md};
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  ol {
    padding: 0 0 0 2.5rem;
  }

  li {
    margin: 0 0 1rem;
    padding: 0 0 0 0.5rem;

    @media ${breakpointUp.md} {
      margin: 0 0 1.25rem;
    }
  }

  ul > li {
    display: flex;
  }

  ul > li::before {
    content: '-';
    margin-right: 1.4375rem;
    transform: translateX(0.3125rem);
    color: ${({ theme }) => theme.cloud};
  }

  a[href] {
    text-decoration: underline;
  }

  blockquote {
    margin: 1.5rem 0;

    @media ${breakpointUp.md} {
      margin: 2.5rem 0;
    }

    ${QuoteText} {
      ${typeScale.mobile.body.lg}

      @media ${breakpointUp.tablet} {
        ${typeScale.tablet.body.xl}
      }

      @media ${breakpointUp.desktop} {
        ${typeScale.desktop.body.xxl}
      }
    }
  }

  dl {
    margin: 2rem 0;

    @media ${breakpointUp.md} {
      margin: 3rem 0;
    }
  }

  .textSecondary {
    color: ${({ theme }) => theme.text500};
  }
`;

export const OldModule = styled(StyledWrapper)`
  grid-column: 1 / -1;
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media ${breakpointUp.desktop} {
    grid-column: 3 / span 6;
  }

  .textSecondary {
    color: ${({ theme }) => theme.text500};
  }
`;
