import React from 'react';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

type ViewportContextShape = {
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
};

interface IViewportContextProvider {
  /** Children to be rendered */
  children: ReactNode;
}

const ViewportContext = createContext<ViewportContextShape | undefined>(
  undefined
);

export const MediaQueries = {
  isMobile: '(width < 768px)',
  isTablet: '(width >= 768px) and (width <= 1024px)',
  isDesktop: '(width > 1024px)',
};

export function ViewportContextProvider({
  children,
}: IViewportContextProvider) {
  const getMatches = (query: string): boolean => {
    if (typeof window !== 'undefined') {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState({
    isDesktop: getMatches(MediaQueries.isDesktop),
    isTablet: getMatches(MediaQueries.isTablet),
    isMobile: getMatches(MediaQueries.isMobile),
  });

  function handleChange() {
    setMatches({
      isDesktop: getMatches(MediaQueries.isDesktop),
      isTablet: getMatches(MediaQueries.isTablet),
      isMobile: getMatches(MediaQueries.isMobile),
    });
  }

  useEffect(() => {
    Object.keys(MediaQueries).forEach((key) => {
      window
        .matchMedia(MediaQueries[key as keyof typeof MediaQueries])
        .addEventListener('change', handleChange, { passive: true });
    });

    return () => {
      Object.keys(MediaQueries).forEach((key) => {
        window
          .matchMedia(MediaQueries[key as keyof typeof MediaQueries])
          .removeEventListener('change', handleChange);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ViewportContext.Provider value={matches}>
      {children}
    </ViewportContext.Provider>
  );
}

/**
 * Returns viewport state from the store.
 *
 * @returns Viewport state.
 * @example
 * const { isMobile } = useViewport();
 */
export const useViewport = () => {
  const context = useContext(ViewportContext);
  if (context === undefined) {
    throw new Error(
      'useViewPort must be used within a ViewportContextProvider'
    );
  }

  return context;
};
