import styled from 'styled-components';

export const ReelContainer = styled.div`
  overflow: hidden;
  height: auto;
  width: 100%;
  aspect-ratio: 1.5 / 1;
  position: relative;
  align-items: center;
  display: flex;
`;
