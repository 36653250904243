import React, { FC } from 'react';
import { CategoryType } from '../../../../old/storyblok/categories-context';
import { TagType } from '../../../../old/storyblok/tags-context';

import {
  Item,
  Section,
  SectorsList,
  StyledWrapper,
  CategoriesWrapper,
  IndustriesWrapper,
} from './OpenFilterList.styles';
import withRouter from '../../../../util/withRouter';
import { PageProps } from 'gatsby';

export type OpenFilterProps = {
  categories: CategoryType[];
  tags: TagType[];
  toggleList?: () => unknown;
  location: PageProps['location'];
};

const OpenFilterList: FC<OpenFilterProps> = ({
  categories,
  tags,
  toggleList,
  location,
}) => {
  return (
    <StyledWrapper>
      <CategoriesWrapper>
        <Section>SERVICE</Section>
        <SectorsList>
          {categories &&
            categories.length > 0 &&
            categories.map((cat) => {
              if (cat.title) {
                return (
                  <li key={cat.uuid}>
                    <Item
                      isActive={location.pathname?.includes(
                        `category/${cat.slug}`
                      )}
                      to={`category/${cat.slug}/`}
                      onClick={() => {
                        if (toggleList) {
                          toggleList();
                        }
                      }}
                    >
                      {cat.title}
                    </Item>
                  </li>
                );
              }
            })}
        </SectorsList>
      </CategoriesWrapper>
      <IndustriesWrapper>
        <Section>INDUSTRY</Section>
        <SectorsList>
          {tags &&
            tags.length > 0 &&
            tags.map((tag) => {
              if (tag.title) {
                return (
                  <li key={tag.uuid}>
                    <Item
                      isActive={location.pathname.includes(`tag/${tag.slug}`)}
                      to={`tag/${tag.slug}/`}
                      onClick={() => {
                        if (toggleList) {
                          toggleList();
                        }
                      }}
                    >
                      {tag.title}
                    </Item>
                  </li>
                );
              }
            })}
        </SectorsList>
      </IndustriesWrapper>
    </StyledWrapper>
  );
};

export default withRouter(OpenFilterList);
