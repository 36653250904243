import React from 'react';
import SbEditable from 'storyblok-react';
import CardReportContent, { CardReportContentProps } from '.';
import { BlokComponent, StoryblokAsset } from '../../../types/blok.model';
import { useReportsThumbnails } from '../../../util/useReportsThumbnails';

export interface CardReportContentBlokProps extends CardReportContentProps {
  contentId: string;
  logo: StoryblokAsset;
  hideDescription?: boolean;
  hideMetaUpper?: boolean;
  hideMetaLower?: boolean;
}

const CardReportContentBlok: BlokComponent<CardReportContentBlokProps> = ({
  blok,
}) => {
  const report = useReportsThumbnails([blok.contentId])?.[0];

  if (!report) {
    return null;
  }

  const thumbnailProps = {
    mobile: { aspect: '3 / 4', thumbnail: report?.thumbnail_3x4 },
    tablet: { aspect: '16 / 9', thumbnail: report?.thumbnail_4x3 },
    desktop: { aspect: '4 / 3', thumbnail: report?.thumbnail_4x3 },
    desktopLarge: { aspect: '4 / 3', thumbnail: report?.thumbnail_4x3 },
  };

  return (
    <SbEditable content={blok}>
      <CardReportContent
        {...blok}
        {...thumbnailProps}
        title={report?.title}
        link={report?.link}
        coverImage={report?.cover_image}
        coverImageAlt={report?.cover_alt}
        thumbnailAlt={report?.thumbnail_alt}
        authors={blok.hideMetaUpper ? undefined : report?.authors}
        description={blok.hideDescription ? undefined : report.description}
        tags={
          blok.hideMetaLower
            ? undefined
            : [...report.tags, ...report.categories]
        }
      />
    </SbEditable>
  );
};

export default CardReportContentBlok;
