import React, { FC } from 'react';
import {
  ColorOverrides,
  List,
  ListItem,
  ListMarker,
  ListWrapper,
  MarkerType,
  Title,
} from './ListBlock.styles';

// FIXME: '/blah.svg?as=component' imports are breaking in storybook (and sometimes in the app?!?)
// import { ReactComponent as CheckMark } from '../../../../assets/icons/check.svg?as=component';
const CheckMark = () => (
  <svg
    width="17"
    height="11"
    viewBox="0 0 17 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 4.88889L1.30769 3.66667L6.53846 8.55556L15.6923 0L17 1.22222L6.53846 11L0 4.88889Z"
      fill="currentColor"
    />
  </svg>
);

export type ListComponentProps = SharedProps &
  ColorOverrides<string> & {
    list: string[];
  };

export interface SharedProps {
  title: string;
  type: MarkerType;
  multiline: boolean;
}

const ListBlock: FC<ListComponentProps> = ({
  list,
  title,
  type,
  multiline,
  ...colorOverrides
}) => {
  return (
    <ListWrapper {...colorOverrides}>
      {title && <Title>{title}</Title>}
      <List>
        {list.map((item) => {
          return (
            <ListItem key={item} tall={multiline}>
              <ListMarker marker={type}>
                {type === 'check' && <CheckMark />}
              </ListMarker>
              <span>{item}</span>
            </ListItem>
          );
        })}
      </List>
    </ListWrapper>
  );
};

export default ListBlock;
