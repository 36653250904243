import { CategoryType } from '../old/storyblok/categories-context';
import isEmpty from './isEmpty';

interface Post {
  id: string;
  title: string;
  categories: CategoryType[];
}

/**
 * Sorts an array of posts based on their featured status.
 *
 * This function takes two optional parameters: `posts` and `featured_posts`.
 * If both are provided, it returns a new array with the following structure:
 * - The featured posts (i.e., those whose IDs are in `featured_posts`) are sorted
 *   by their order of appearance in `featured_posts`.
 * - The non-featured posts (i.e., those not in `featured_posts`) are appended after
 *   the featured posts.
 *
 * If only one of `posts` or `featured_posts` is provided, it returns the original array.
 *
 * @param posts - An optional array of post objects to sort.
 * @param featured_posts - An optional array of post IDs that are considered featured.
 * @returns The sorted array of posts, or the original array if only one parameter is provided.
 */
export const sortFeaturedPosts = <T extends Post>(
  posts?: T[],
  featured_posts?: string[]
) =>
  posts && featured_posts
    ? [
        ...posts
          .filter((post) => featured_posts.includes(post.id))
          .sort(
            (a, b) =>
              featured_posts.indexOf(a.id) - featured_posts.indexOf(b.id)
          ),
        ...posts.filter((post) => !featured_posts.includes(post.id)),
      ]
    : posts;

/**
 * Transforms an array of posts with categories by removing
 * the extraneous folder pathname from each category's full_slug property.
 *
 * @param posts - The array of Post objects to transform.
 * @returns The transformed array of Post objects.
 */
export const transformCategorySlugs = <T extends Post>(posts?: T[]) =>
  posts
    ? posts.map((post) => ({
        ...post,
        categories: post.categories?.map((category) => ({
          ...category,
          full_slug: category.full_slug.replace(`${category.slug}/`, ''),
        })),
      }))
    : posts;

/**
 * Transforms and sorts posts based on specified criteria.
 *
 * @template T - The type of the posts being transformed, extending the base Post type.
 * @param posts - Array of posts to be transformed. Each post must have an `id`, `title`, and optionally `categories`.
 * @param featured_posts - Array of string ids to be sorted.
 * @returns Array of Transformed and sorted array of posts or undefined if no valid posts.
 */
export const transformCollection = <T extends Post>(
  posts?: T[],
  featured_posts?: string[]
) => {
  const postsWithData = posts && posts.filter((post) => !isEmpty(post.title));
  const sortedPosts = sortFeaturedPosts(postsWithData, featured_posts);
  const transformedPosts = transformCategorySlugs(sortedPosts);
  return transformedPosts;
};

export default transformCollection;
