import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import { Grid } from '../../layout/Grid';
import { spacing } from '../../../styles/newThemeVariables.styles';
import { typeScale } from '../../../styles/newTypography';
import { ResponsiveImage } from '../../components/ResponsiveImage';
import CallToActionLink from '../../components/CallToActionLink';
import { rgba } from 'polished';
import { TextBlokProps } from './TextBlock';

export const StyledGrid = styled(Grid)`
  position: relative;
  align-items: center;
  aspect-ratio: 5 / 10;

  @media ${breakpointUp.tablet} {
    aspect-ratio: 1 / 1;
  }

  @media ${breakpointUp.desktop} {
    min-height: 100%;
    align-items: stretch;
    aspect-ratio: 16 / 8.375;
  }

  @media ${breakpointUp.desktopLarge} {
    min-height: 100%;
    align-items: stretch;
    aspect-ratio: 16 / 8.375;
  }

  video[poster] {
    object-fit: cover;
  }
`;

export const EyebrowText = styled.div`
  grid-column: 1 / -1;
  ${typeScale.mobile.mono.md};
  margin-bottom: ${spacing.space12};

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.mono.md};
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.mono.md};
    margin-bottom: ${spacing.space24};
  }
`;

export const Title = styled.h1<{ $textWrap: TextBlokProps['textWrap'] }>`
  grid-column: 1 / -1;
  ${typeScale.mobile.body.xl};
  margin-top: 0;
  margin-bottom: ${spacing.space12};
  text-wrap: ${({ $textWrap }) => $textWrap};
  white-space: break-spaces;

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.body.xl};
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.xl};
    margin-bottom: ${spacing.space16};
    max-width: 20.25rem;
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.xxl};
    margin-bottom: ${spacing.space16};
    max-width: 26.25rem;
  }

  @media ${breakpointUp.xl} {
    grid-column: 1 / span 4;
  }
`;

export const Description = styled.div`
  grid-column: 1 / -1;
  ${typeScale.mobile.body.xs};
  margin-bottom: 1.5rem;

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.body.xs};
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.sm};
    padding-bottom: ${spacing.space12};
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.sm};
    padding-bottom: ${spacing.space12};
    max-width: 26.25rem;
  }

  @media ${breakpointUp.xl} {
    grid-column: 1 / span 4;
  }
`;

export const CTALink = styled(CallToActionLink)`
  grid-column: 1 / -1;
`;

export const StyledBgImage = styled(ResponsiveImage)`
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: calc(100% + 2rem);
  height: 100%;
  margin: 0 -1rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media ${breakpointUp.tablet} {
    margin: 0 -1.5rem;
    width: calc(100% + 3rem);
  }

  @media ${breakpointUp.desktop} {
    margin: 0;
    width: 100%;
  }
`;

export const Center = styled.div<{
  $opacity: number;
  $textColor: TextBlokProps['textColor'];
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  grid-column: 1 / -1;
  padding: 0 ${spacing.space24};
  margin: ${spacing.space136} 0;
  min-height: 28.875rem;

  border-radius: ${spacing.space10};
  background-color: rgba(255, 255, 255, ${({ $opacity }) => $opacity});
  box-shadow: 0 1.25rem 3.75rem 0 rgba(0, 0, 0, 0.2),
    0 0.125rem 0.4375rem 0 rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(1.875rem);

  @media ${breakpointUp.tablet} {
    grid-column: 7 / -1;
    padding: 0 ${spacing.space24};
    margin: ${spacing.space128} 0;
  }

  @media ${breakpointUp.desktop} {
    grid-column: 9 / -1;
    padding: ${spacing.space56} ${spacing.space32};
    margin: ${spacing.space36} ${spacing.space36} ${spacing.space36} 0;
  }

  ${EyebrowText}, ${Title} {
    color: ${({ theme, $textColor }) =>
      $textColor ? theme[$textColor] : theme.textPrimary};
  }

  ${CTALink} {
    color: ${({ theme, $textColor }) =>
      $textColor ? theme[$textColor] : theme.textPrimary};

    border-color: ${({ theme, $textColor }) =>
      $textColor ? rgba(theme[$textColor], 0.8) : theme.textPrimary};

    &:hover {
      border-color: ${({ theme, $textColor }) =>
        $textColor ? rgba(theme[$textColor], 0.5) : theme.textPrimary};
    }
  }

  ${Description} {
    color: ${({ theme, $textColor }) =>
      $textColor ? theme[$textColor] : theme.textPrimary};
  }
`;
