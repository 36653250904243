import styled from 'styled-components';
import { ResponsiveImage } from '../../components/ResponsiveImage';
import CMSLink from '../../components/CMSLink';
import { typeScale } from '../../../styles/newTypography';
import { spacing } from '../../../styles/newThemeVariables.styles';
import { breakpointUp } from '../../../styles/mediaUtils';
import { ListItem } from '../../modules/CardList/CardList.styles';

export const Image = styled(ResponsiveImage)<{
  $aspectMobile?: string;
  $aspectTablet?: string;
  $aspectDesktop?: string;
  $aspectDesktopLarge?: string;
}>`
  grid-column: 1 / -1;
  aspect-ratio: ${({ $aspectMobile }) => $aspectMobile ?? '3 / 4'};

  & > div {
    height: 100%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media ${breakpointUp.tablet} {
    aspect-ratio: ${({ $aspectTablet }) => $aspectTablet ?? '3 / 4'};
  }

  @media ${breakpointUp.desktop} {
    aspect-ratio: ${({ $aspectDesktop }) => $aspectDesktop ?? '3 / 4'};
  }

  @media ${breakpointUp.desktopLarge} {
    aspect-ratio: ${({ $aspectDesktopLarge }) =>
      $aspectDesktopLarge ?? '3 / 4'};
  }
`;

export const Link = styled(CMSLink)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${spacing.space16};
  grid-column: 1 / -1;
  cursor: pointer;
  margin-bottom: ${spacing.space32};

  ${Image} {
    opacity: 1;
    transition: opacity 300ms ease-out;
  }

  &:hover {
    ${Image} {
      opacity: 0.85;
    }
  }

  @media ${breakpointUp.tablet} {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: ${spacing.space16};
    margin-bottom: ${spacing.space32};
  }

  @media ${breakpointUp.desktop} {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: ${spacing.space24};
    margin-bottom: ${spacing.space32};
  }

  @media ${breakpointUp.desktopLarge} {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: ${spacing.space24};
    margin-bottom: ${spacing.space32};
  }
`;

export const Details = styled(Link)`
  align-content: start;
  margin-bottom: 0;

  @media ${breakpointUp.tablet} {
    margin-bottom: 0;
  }

  @media ${breakpointUp.desktop} {
    margin-bottom: 0;
  }
`;

export const Title = styled.h2`
  grid-column: 1 / -1;
  ${typeScale.mobile.body.lg};
  margin-bottom: ${spacing.space16};

  @media ${breakpointUp.tablet} {
    ${typeScale.desktop.body.lg};
    margin-bottom: ${spacing.space12};
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.lg};
    margin-bottom: ${spacing.space12};
  }
`;

export const Description = styled.h3`
  grid-column: 1 / -1;
  ${typeScale.mobile.body.xs};
  margin-bottom: ${spacing.space24};
  color: ${({ theme }) => theme.text400};

  @media ${breakpointUp.tablet} {
    ${typeScale.desktop.body.sm};
    margin-bottom: ${spacing.space16};
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.sm};
    margin-bottom: ${spacing.space16};
  }
`;

export const Author = styled.div`
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  column-gap: ${spacing.space16};
  margin-bottom: ${spacing.space16};
  word-break: normal;
`;

export const AuthorDetails = styled.div`
  display: flex;
  column-gap: ${spacing.space8};
  align-items: center;
  text-align: center;
`;

export const AuthorAvatar = styled(ResponsiveImage)`
  border-radius: 6.25rem;
  max-width: 2.5rem;
  max-height: 2.5rem;
  overflow: hidden;
`;

export const AuthorName = styled.h2`
  ${typeScale.desktop.body.xs};
  margin-bottom: 0;

  @media ${breakpointUp.tablet} {
    ${typeScale.desktop.body.xs};
  }
`;

export const AuthorDescription = styled.h2`
  ${typeScale.desktop.body.xs};
  color: ${({ theme }) => theme.text400};
  margin-bottom: 0;

  @media ${breakpointUp.tablet} {
    ${typeScale.desktop.body.xs};
  }
`;

export const Card = styled(ListItem)`
  @media ${breakpointUp.tablet} {
    .tablet-one-column & {
      ${Title} {
        ${typeScale.desktop.body.xl};
      }

      ${Description} {
        ${typeScale.desktop.body.md};
      }
    }

    .tablet-two-column-highlight & {
      ${Image} {
        aspect-ratio: 4 / 3;
      }

      ${Link} {
        margin-bottom: ${spacing.space16};
      }

      ${Details} {
        margin-bottom: 0;
      }

      ${Author} {
        margin-bottom: 0;
      }

      ${AuthorName} {
        ${typeScale.desktop.body.xs};
      }

      ${Title} {
        ${typeScale.desktop.body.md};
      }

      :first-child {
        grid-column: span 2;
      }
    }
  }

  @media ${breakpointUp.desktop} {
    .desktop-two-column & {
      ${Title} {
        grid-column: span 5;
        ${typeScale.desktop.body.xl};
      }

      ${Description} {
        grid-column: span 5;
        ${typeScale.desktop.body.md};
      }
    }

    .desktop-two-column-highlight & {
      :first-child {
        grid-column: span 2;

        ${Image} {
          aspect-ratio: 21 / 9;
        }
      }
    }

    .desktop-four-column-highlight & {
      display: grid;
      grid-template-rows: 1fr auto;
      height: 100%;

      ${Link} {
        margin-bottom: ${spacing.space16};
      }

      ${Details} {
        min-height: calc(${spacing.space56} + ${spacing.space88});
        margin-bottom: 0;
      }

      ${Image} {
        aspect-ratio: 4 / 3;
        height: 100%;
      }

      ${Author} {
        margin-bottom: 0;
      }

      ${AuthorName} {
        ${typeScale.desktop.body.xs};
      }

      ${Title} {
        ${typeScale.desktop.body.md};
      }

      :first-child {
        grid-column: span 2;
        grid-row: span 2;

        ${Image} {
          aspect-ratio: auto;
          height: 100%;
        }
      }
    }
  }

  @media ${breakpointUp.desktopLarge} {
    .desktop-large-two-column & {
      ${Title} {
        grid-column: span 3;
        ${typeScale.desktop.body.xxl};
      }

      ${Description} {
        grid-column: span 3;
        ${typeScale.desktop.body.lg};
      }
    }

    .desktop-large-two-column-highlight & {
      :first-child {
        grid-column: span 2;

        ${Image} {
          aspect-ratio: 21 / 9;
        }
      }
    }

    .desktop-large-four-column & {
      ${Description} {
        ${typeScale.mobile.body.xs};
      }
    }

    .desktop-large-four-column-highlight & {
      display: grid;
      grid-template-rows: 1fr auto;
      height: 100%;

      ${Link} {
        margin-bottom: ${spacing.space16};
      }

      ${Details} {
        min-height: calc(${spacing.space56} + ${spacing.space112});
        margin-bottom: 0;
      }

      ${Image} {
        aspect-ratio: 4 / 3;
        height: 100%;
      }

      ${Author} {
        margin-bottom: 0;
      }

      ${AuthorName} {
        ${typeScale.desktop.body.xs};
      }

      ${Title} {
        ${typeScale.desktop.body.xl};
      }

      :first-child {
        grid-column: span 2;
        grid-row: span 2;

        ${Image} {
          aspect-ratio: auto;
          height: 100%;
        }
      }
    }
  }
`;
