import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';

export const StyledMenu = styled.menu`
  background-color: ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.fg};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 8rem 0 0 0;
  margin: 0;
  overflow: auto;
  z-index: 601;

  @media ${breakpointUp.md} {
    padding: 11rem 0 0 0;
  }

  transition: opacity 150ms ease-out;

  &.menu-transition-appear,
  &.menu-transition-enter {
    opacity: 0;
  }

  &.menu-transition-appear-active,
  &.menu-transition-enter-active {
    opacity: 1;
  }

  &.menu-transition-exit {
    opacity: 1;
  }

  &.menu-transition-exit-active {
    opacity: 0;
  }
`;

export const StyledCloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  border: none;
  appearance: none;
  cursor: pointer;
  padding: 2.75rem 1.75rem 2rem;
  background: transparent;
  color: ${({ theme }) => theme.fg};
  line-height: 1;

  :active {
    background: transparent;
  }

  svg {
    fill: ${({ theme }) => theme.fg};
  }

  @media ${breakpointUp.md} {
    display: none;
  }
`;

export const StyledMainList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 3rem;
  font-weight: 300;
  line-height: 4rem;

  li {
    width: 100%;
    margin-bottom: 1.75rem;

    :last-child {
      margin-bottom: 6rem;
    }
  }

  @media ${breakpointUp.xs} {
    li {
      margin-bottom: 2.5rem;

      :last-child {
        margin-bottom: 4rem;
      }
    }
  }
`;
