import React from 'react';
import SbEditable from 'storyblok-react';
import CardCaseStudy, { CardCaseStudyProps } from '.';
import { BlokComponent } from '../../../types/blok.model';
import { useCaseStudyThumbnails } from '../../../util/useCaseStudyThumbnails';

export interface CardCaseStudyBlokProps extends CardCaseStudyProps {
  contentId: string;
  hideDescription?: boolean;
  hideMetaUpper?: boolean;
  hideMetaLower?: boolean;
}

const CardCaseStudyBlok: BlokComponent<CardCaseStudyBlokProps> = ({ blok }) => {
  const caseStudy = useCaseStudyThumbnails([blok.contentId])?.[0];

  if (!caseStudy) {
    return null;
  }

  const thumbnailProps = {
    mobile: { aspect: '3 / 4', thumbnail: caseStudy.thumbnail_3x4 },
    tablet: { aspect: '16 / 9', thumbnail: caseStudy.thumbnail_16x9 },
    desktop: { aspect: '3 / 4', thumbnail: caseStudy.thumbnail_3x4 },
    desktopLarge: { aspect: '4 / 3', thumbnail: caseStudy.thumbnail_4x3 },
  };

  const logo = {
    clientLogo: caseStudy.logo,
    clientLogoDark: caseStudy.logoDark,
    clientName: caseStudy.client,
    clientLogoAlt: caseStudy.client,
  };

  return (
    <SbEditable content={blok}>
      <CardCaseStudy
        {...blok}
        {...thumbnailProps}
        {...(blok.hideMetaUpper ? undefined : logo)}
        title={caseStudy.title}
        link={caseStudy.link}
        thumbnailAlt={caseStudy.thumbnail_alt}
        tags={
          blok.hideMetaLower
            ? undefined
            : [...caseStudy.tags, ...caseStudy.categories]
        }
      />
    </SbEditable>
  );
};

export default CardCaseStudyBlok;
