import React from 'react';
import Eyebrow from '../../components/Eyebrow';
import { Title, Body, Meta, Container } from './TextFeatureBlock.styles';
import CallToActionLink from '../../components/CallToActionLink';

export interface TextFeatureBlockProps {
  eyebrow?: string;
  title?: string;
  body?: React.ReactNode;
  ctaLink?: string;
  ctaText?: string;
  variant?: 'highlight' | 'title' | 'two-column';
}

const TextFeatureBlock: React.FC<TextFeatureBlockProps> = ({
  eyebrow,
  title,
  body,
  ctaLink,
  ctaText,
  variant = 'highlight',
}) => {
  return (
    <Container className={variant}>
      {eyebrow && <Eyebrow text={eyebrow} />}
      {title && <Title>{title}</Title>}
      {body && <Body>{body}</Body>}
      {ctaText && (
        <Meta>
          <CallToActionLink size="md" href={ctaLink}>
            {ctaText}
          </CallToActionLink>
        </Meta>
      )}
    </Container>
  );
};

export default TextFeatureBlock;
