import React, { FC } from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { useThemeMode } from '../../../util/useThemeMode';
import { TagType } from '../../../old/storyblok/tags-context';
import {
  ClientName,
  ClientLogo,
  Link,
  Details,
  Image,
  Card,
  Title,
} from './CardCaseStudy.styles';
import TagsBlock from '../TagsBlock';

type LogoProps = {
  clientLogo?: string | IGatsbyImageData;
  clientLogoDark?: string | IGatsbyImageData;
  clientName?: string;
  clientLogoAlt?: string;
};

export type CardCaseStudyProps = LogoProps & {
  title?: string;
  link?: string;
  thumbnailAlt?: string;
  mobile?: {
    aspect?: string;
    thumbnail?: string | IGatsbyImageData;
  };
  tablet?: {
    aspect?: string;
    thumbnail?: string | IGatsbyImageData;
  };
  desktop?: {
    aspect?: string;
    thumbnail?: string | IGatsbyImageData;
  };
  desktopLarge?: {
    aspect?: string;
    thumbnail?: string | IGatsbyImageData;
  };
  tags?: TagType[];
};

const Logo: FC<LogoProps> = ({
  clientLogo,
  clientLogoDark,
  clientName,
  clientLogoAlt = clientName ?? 'client logo',
}) => {
  const { themeMode } = useThemeMode();
  const logo =
    themeMode === 'dark' && clientLogoDark ? clientLogoDark : clientLogo;

  return (
    <>
      {logo ? (
        <ClientLogo
          alt={clientLogoAlt}
          mobile={logo}
          tablet={logo}
          desktop={logo}
          loading="eager"
        />
      ) : (
        <ClientName>{clientName}</ClientName>
      )}
    </>
  );
};

const CardCaseStudy: FC<CardCaseStudyProps> = ({
  title,
  link,
  clientLogo,
  clientLogoDark,
  clientName,
  clientLogoAlt,
  thumbnailAlt,
  mobile,
  tablet,
  desktop,
  desktopLarge,
  tags,
}) => {
  return (
    <Card>
      <Link to={link ?? ''}>
        {thumbnailAlt &&
          mobile?.thumbnail &&
          tablet?.thumbnail &&
          desktop?.thumbnail && (
            <Image
              alt={thumbnailAlt}
              mobile={mobile.thumbnail}
              tablet={tablet.thumbnail}
              desktop={desktop.thumbnail}
              $aspectMobile={mobile.aspect}
              $aspectTablet={tablet.aspect}
              $aspectDesktop={desktop.aspect}
              $aspectDesktopLarge={desktopLarge?.aspect}
            />
          )}
      </Link>
      <Details to={link ?? ''}>
        <Logo
          clientLogo={clientLogo}
          clientLogoDark={clientLogoDark}
          clientLogoAlt={clientLogoAlt}
          clientName={clientName}
        />
        {title && <Title>{title}</Title>}
      </Details>
      {tags && <TagsBlock tags={tags} />}
    </Card>
  );
};

export default CardCaseStudy;
