import styled from 'styled-components';
import { typeScale } from '../../../styles/newTypography';
import { spacing } from '../../../styles/newThemeVariables.styles';
import { breakpointUp } from '../../../styles/mediaUtils';
import { motion } from 'framer-motion';

export const Title = styled.p`
  ${typeScale.mobile.mono.md};
  margin-bottom: ${spacing.space8};
  grid-column: 1 / -1;

  @media ${breakpointUp.tablet} {
    margin-bottom: ${spacing.space56};
  }
`;

export const ReelModuleWrapper = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: row;
  column-gap: 0;
  padding: 0;

  @media ${breakpointUp.tablet} {
    column-gap: ${spacing.space64};
    padding: 0 ${spacing.space24} 0 0;
  }
`;

export const Item = styled(motion.div)`
  width: 100%;
`;
