import styled, { css } from 'styled-components';
import ContentWrapper from '../../../old/components/style-components/ContentWrapper';
import Row from '../../../old/components/style-components/Grid';
import { breakpointUp } from '../../../styles/mediaUtils';
import { textSizes } from '../../../styles/typography';
import StoryblokAssetImage from '../../components/AssetImage';
import Sectors from '../../components/Sectors';
import { HeaderLayout } from '.';

export const StyledContentWrapper = styled(ContentWrapper)`
  padding: 0 1.5rem;

  @media ${breakpointUp.sm} {
    padding: 0 3.5rem;
  }
`;

export const Header = styled.header<{ bgColor?: string }>`
  padding-top: 11.75rem;
  position: relative;
  background-color: ${({ bgColor }) => bgColor};

  @media ${breakpointUp.sm} {
    padding-top: 16.2813rem;
  }

  @media ${breakpointUp.md} {
    padding-top: 14rem;
  }

  @media ${breakpointUp.xl} {
    padding-top: 14.0313rem;
  }
`;

export const BackgroundImage = styled(StoryblokAssetImage)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  .gatsby-image-wrapper {
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const StyledRow = styled(Row)`
  display: flex;
  flex-direction: column;
`;

export const HeaderTitle = styled.h1<{
  layout: HeaderLayout;
}>`
  grid-column: span 2;
  ${textSizes.xl};
  color: var(--fg1, ${({ theme }) => theme.fg});
  margin: 0;
  white-space: break-spaces;
  text-wrap: balance;
  display: block;

  @media ${breakpointUp.sm} {
    ${textSizes.xxl};
    grid-column: span 12;
  }

  @media ${breakpointUp.md} {
    ${textSizes.xxxl};
    grid-column: ${({ layout }) =>
      layout === 'left-aligned' ? '1 / span 6' : '3 / span 8'};
  }
`;

export const StyledTags = styled(Sectors)<{
  underlineColor?: string;
  fontColor?: string;
  layout: HeaderLayout;
}>`
  grid-column: span 2;

  @media ${breakpointUp.sm} {
    grid-column: span 12;
  }

  @media ${breakpointUp.md} {
    grid-column: ${({ layout }) =>
      layout === 'left-aligned' ? '1 / span 7' : '3 / span 8'};
  }

  ${({ fontColor, underlineColor }) => css`
    margin-bottom: 3rem; /* to prevent being too close to title */

    && li {
      color: ${fontColor};
      border-bottom: 0.125rem solid ${underlineColor};
      ${textSizes.xs};

      @media ${breakpointUp.md} {
        ${textSizes.xxs};
      }
    }
  `}
`;

export const StyledFooter = styled.div<{
  layout: HeaderLayout;
}>`
  grid-column: span 2;
  padding: 2rem 0 1.5rem 0;

  ${({ layout }) => css`
    @media ${breakpointUp.sm} {
      grid-column: span 12;
      padding: 3.5rem 0 2rem 0;
    }

    @media ${breakpointUp.md} {
      grid-column: ${layout === 'left-aligned' ? '1 / span 7' : '3 / span 8'};
      padding: 3.5rem 0 3.25rem 0;
    }
  `}
`;
