import React, { FC } from 'react';
import { AuthorType } from '../../../../old/storyblok/authors-context';
import { StyledPicture } from './Avatar.styles';

export interface AvatarProps {
  author: AuthorType;
  className?: string;
}

const Avatar: FC<AvatarProps> = ({ author, ...rest }) => {
  return (
    <StyledPicture
      lazyLoad={false}
      fallbackSrc={author?.avatar}
      fallbackDimensions={[48, 48]}
      alt={author?.name}
      {...rest}
    />
  );
};

export default Avatar;
