import React from 'react';
import SbEditable, { SbEditableContent } from 'storyblok-react';
import {
  BlokComponent,
  NewStoryblokMetaData,
  StoryblokRelatedPosts,
} from '../../types/blok.model';
import RenderAfterPageTransition from '../../old/components/RenderAfterPageTransition';
import styled, { ThemeProvider } from 'styled-components';
import defaultTheme, { darkTheme } from '../../styles/themeVariables';

import InlineFilterModule from '../modules/InlineFilterModule';
import CardList from '../modules/CardList';
import CardBlogContent from '../blocks/CardBlogContent';
import { Layout } from '../layout/Layout';
import { Grid } from '../layout/Grid';
import { Section } from '../layout/Section';
import { Pagination } from '../components/Pagination/Pagination';

import { spacing } from '../../styles/newThemeVariables.styles';
import { useBlogPostThumbnails } from '../../util/useBlogPostThumbnails';
import { useBlogPosts } from '../../old/storyblok/blogPosts-context';
import { useViewport } from '../../util/useViewport';
import transformCollection from '../../util/transformCollection';

interface BlogCollectionPageSchema extends NewStoryblokMetaData {
  headerTitle: string;
  featured_posts: StoryblokRelatedPosts;
  body?: SbEditableContent[];
  bodyLower?: SbEditableContent[];
}

const Background = styled.div`
  background: ${({ theme }) => theme.bgPrimary};
`;

const ListContainer = styled.div`
  margin-bottom: ${spacing.space112};
`;

const BlogCollectionsPage: BlokComponent<BlogCollectionPageSchema> = ({
  blok,
  Dynamic,
}) => {
  // handle pagination
  const { pagination, posts } = useBlogPosts();
  const postUuids = posts?.map((post) => post.uuid);
  const convertedBlogPosts = useBlogPostThumbnails(postUuids);
  const transformedPosts =
    convertedBlogPosts &&
    transformCollection(convertedBlogPosts, blok.featured_posts);
  const { isMobile } = useViewport();

  return (
    <SbEditable content={blok}>
      <ThemeProvider theme={defaultTheme}>
        <Background>
          <RenderAfterPageTransition>
            <InlineFilterModule
              headerTitle={blok.headerTitle}
              resetLabel="All blog posts"
              resetFilterUrl="blog/"
            />

            {transformedPosts && (
              <>
                <ListContainer>
                  <CardList
                    scrollMobile={false}
                    mobile={{
                      rowGap: spacing.space72,
                      variant: 'one-column',
                    }}
                    tablet={{
                      rowGap: spacing.space112,
                      variant: 'two-column',
                    }}
                    desktop={{
                      rowGap: spacing.space112,
                      variant: 'three-column',
                    }}
                    desktopLarge={{
                      rowGap: spacing.space112,
                      variant: 'four-column',
                    }}
                  >
                    {transformedPosts.map((blogPost) => (
                      <CardBlogContent
                        key={blogPost.id}
                        title={blogPost.title}
                        link={blogPost.link}
                        mobile={{
                          aspect: '3 / 4',
                          thumbnail: blogPost.thumbnail_3x4,
                        }}
                        tablet={{
                          aspect: '3 / 4',
                          thumbnail: blogPost.thumbnail_3x4,
                        }}
                        desktop={{
                          aspect: '3 / 4',
                          thumbnail: blogPost.thumbnail_3x4,
                        }}
                        desktopLarge={{
                          aspect: '3 / 4',
                          thumbnail: blogPost.thumbnail_3x4,
                        }}
                        thumbnailAlt={blogPost.thumbnail_alt}
                        description={blogPost.description}
                        tags={[...blogPost.tags, ...blogPost.categories]}
                      />
                    ))}
                  </CardList>
                </ListContainer>

                {pagination?.currentPage && pagination?.numPages > 1 && (
                  <Section>
                    <Layout>
                      <Grid>
                        <Pagination
                          siblings={{
                            left: isMobile ? 1 : 2,
                            right: isMobile ? 1 : 1,
                          }}
                          currentPage={pagination?.currentPage}
                          totalNumberOfPages={pagination?.numPages}
                          hrefBuilder={(page) => {
                            if (page === 1) {
                              return '/blog/';
                            }

                            return `/blog/${page}`;
                          }}
                        />
                      </Grid>
                    </Layout>
                  </Section>
                )}
              </>
            )}

            {blok.body?.map((insideBlok) => (
              <Dynamic key={insideBlok._uid} blok={insideBlok} />
            ))}
          </RenderAfterPageTransition>
        </Background>
      </ThemeProvider>
      <ThemeProvider theme={darkTheme(defaultTheme)}>
        <Background>
          <RenderAfterPageTransition>
            {blok.bodyLower?.map((insideBlok) => (
              <Dynamic key={insideBlok._uid} blok={insideBlok} />
            ))}
          </RenderAfterPageTransition>
        </Background>
      </ThemeProvider>
    </SbEditable>
  );
};

export default React.memo(BlogCollectionsPage);
