import styled from 'styled-components';
import { Col } from '../../../old/components/style-components/Grid';
import { breakpointUp } from '../../../styles/mediaUtils';
import { textSizes } from '../../../styles/typography';
import { TextModuleProps } from '.';

export const Heading = styled.h2<{
  layout: TextModuleProps['titleLayout'];
}>`
  margin-bottom: 2rem;
  color: var(--fg1, ${({ theme }) => theme.fg});
  grid-column: span 2;
  text-wrap: balance;

  dl {
    margin: 0;
  }

  dt {
    ${textSizes.xs}
    margin-bottom: 1.5rem;

    &::after {
      content: '';
      padding-bottom: 1rem;
      display: block;
      width: 1.5rem;
      border-bottom: 0.125rem solid var(--fg3, ${({ theme }) => theme.blue});
    }
  }

  dd {
    ${textSizes.lg}
    margin: 0;
    white-space: break-spaces;
    text-wrap: balance;
  }

  @media ${breakpointUp.sm} {
    margin-bottom: 3rem;
    grid-column: ${({ layout = 'standard' }) => {
      switch (layout) {
        default:
          return 'span 8';
      }
    }};

    dd {
      ${textSizes.xl}
    }
  }

  @media ${breakpointUp.md} {
    margin-bottom: 4rem;
    grid-column: ${({ layout = 'standard' }) => {
      switch (layout) {
        case 'offset':
          return '1 / span 4';
        case '50-50':
          return '1 / span 5';
        case 'standard':
          return '1 / 9';
        default:
          return '1 / 9';
      }
    }};

    dt {
      margin-bottom: 1.5rem;
    }

    dd {
      ${textSizes.xxxl}
    }
  }
`;

export const TextCol = styled(Col)`
  ${textSizes.xs}
  margin-bottom: 2rem;
  color: var(--fg2, ${({ theme }) => theme.fgFaded});

  p:last-child {
    margin-bottom: 0;
  }

  a {
    margin-top: 2.75rem;
  }

  @media ${breakpointUp.sm} {
    margin-bottom: 0;
  }

  @media ${breakpointUp.md} {
    ${textSizes.md}

    a {
      margin: 1.625rem 0 0 0;
    }
  }
`;

export const CTACol = styled.div`
  width: auto;
  grid-column: 1 / -1;

  a {
    margin-top: 2.75rem;
  }

  @media ${breakpointUp.md} {
    grid-column: 1 / span 12;

    a {
      margin-top: 1.625rem;
    }
  }
`;

export const BlockContentOne = styled.div<{
  layout: TextModuleProps['layout'];
}>`
  grid-column: span 2;
  margin-bottom: 1.5rem;

  @media ${breakpointUp.sm} {
    grid-column: ${({ layout = 'standard' }) => {
      switch (layout) {
        case 'offset':
          return '1 / span 4';
        case '50-50':
          return 'span 6';
        case 'standard':
          return '1 / 8';
        default:
          return '1 / -1';
      }
    }};
  }

  @media ${breakpointUp.md} {
    grid-column: ${({ layout = 'standard' }) => {
      switch (layout) {
        case 'offset':
          return '1 / span 4';
        case '50-50':
          return '1 / span 5';
        case 'standard':
          return '1 / span 6';
        default:
          return '1 / -1';
      }
    }};
  }
`;

export const BlockContentTwo = styled.div<{
  layout: TextModuleProps['layout'];
}>`
  grid-column: span 2;

  @media ${breakpointUp.sm} {
    grid-column: ${({ layout = 'standard' }) => {
      switch (layout) {
        case 'offset':
          return '6 / span 7';
        case '50-50':
          return '7 / span 6';
        case 'standard':
          return '8 / -1';
        default:
          return '1 / -1';
      }
    }};
  }

  @media ${breakpointUp.md} {
    grid-column: ${({ layout = 'standard' }) => {
      switch (layout) {
        case 'offset':
          return '6 / span 6';
        case '50-50':
          return '7 / span 5';
        case 'standard':
          return '8 / -1';
        default:
          return '1 / -1';
      }
    }};
  }
`;
