import styled, { css } from 'styled-components';
import { textSizes } from '../../../styles/typography';
import { breakpointUp } from '../../../styles/mediaUtils';

export interface ColorOverrides<T> {
  quoteColor?: T;
  authorColor?: T;
  credentialsColor?: T;
  sidebarColor?: T;
}

export const QuoteText = styled.p`
  ${textSizes.sm};
  color: ${({ theme }) => theme.textPrimary};
`;

export const Cite = styled.cite`
  ${textSizes.xxs};
  font-style: normal;
`;

export const Author = styled.div`
  color: var(--fg2, ${({ theme }) => theme.greyMedium});
`;

export const Credentials = styled.div`
  color: var(--fg3, ${({ theme }) => theme.cloud});
`;

type BlockquoteProps = ColorOverrides<string>;
export const Blockquote = styled.blockquote<BlockquoteProps>`
  ${({ theme, quoteColor, authorColor, credentialsColor, sidebarColor }) =>
    css`
      margin: 0;
      padding-left: 1.125rem;
      border-left: 0.125rem solid ${sidebarColor || theme.blue};
      box-sizing: border-box;

      ${QuoteText} {
        ${textSizes.md};
        color: ${quoteColor};
      }
      ${Author} {
        color: ${authorColor};
      }
      ${Credentials} {
        color: ${credentialsColor};
      }

      ${Cite} {
        ${textSizes.xxs};
      }

      @media ${breakpointUp.sm} {
        ${QuoteText} {
          ${textSizes.lg}
        }
        ${Cite} {
          ${textSizes.xxs};
        }
      }

      @media ${breakpointUp.md} {
        padding-left: 1.5rem;

        ${QuoteText} {
          ${textSizes.xl};
        }
        ${Cite} {
          ${textSizes.xs};
        }
      }

      @media ${breakpointUp.lg} {
        padding-left: 2rem;
      }
    `}
`;
