import React from 'react';
import SbEditable, { SbEditableContent } from 'storyblok-react';
import { BlokComponent, NewStoryblokMetaData } from '../../types/blok.model';
import { useNavTheme } from '../../old/components/Nav/NavThemeSetter';
import defaultTheme, { darkTheme } from '../../styles/themeVariables';
import RenderAfterPageTransition from '../../old/components/RenderAfterPageTransition';
import { ThemeProvider } from 'styled-components';
import { ThemeModeContextProvider } from '../../util/useThemeMode';
import SpaceWrapper from '../components/SpaceWrapper';
import { spacing } from '../../styles/newThemeVariables.styles';

export interface AiPageSchema extends NewStoryblokMetaData {
  body?: SbEditableContent[];
  bodyLower?: SbEditableContent[];
  lightHeader: boolean;
}

const AiPage: BlokComponent<AiPageSchema> = ({ blok, Dynamic }) => {
  useNavTheme(blok.lightHeader ? defaultTheme : darkTheme(defaultTheme));

  return (
    <SbEditable content={blok}>
      <ThemeModeContextProvider themeMode={'light'}>
        <ThemeProvider theme={defaultTheme}>
          <SpaceWrapper
            customSpacing={{
              mobile: {
                paddingTop: spacing.space184,
                paddingBottom: spacing.space144,
              },
              tablet: {
                paddingTop: spacing.space264,
                paddingBottom: spacing.space144,
              },
              desktop: {
                paddingTop: spacing.space294,
                paddingBottom: spacing.space200,
              },
              desktopLarge: {
                paddingTop: spacing.space378,
                paddingBottom: spacing.space200,
              },
            }}
          >
            <RenderAfterPageTransition>
              {blok.body?.map((insideBlok) => (
                <Dynamic key={insideBlok._uid} blok={insideBlok} />
              ))}
            </RenderAfterPageTransition>
          </SpaceWrapper>
        </ThemeProvider>
      </ThemeModeContextProvider>
      <ThemeModeContextProvider themeMode={'dark'}>
        <ThemeProvider theme={darkTheme(defaultTheme)}>
          <SpaceWrapper>
            <RenderAfterPageTransition>
              {blok.bodyLower?.map((insideBlok) => (
                <Dynamic key={insideBlok._uid} blok={insideBlok} />
              ))}
            </RenderAfterPageTransition>
          </SpaceWrapper>
        </ThemeProvider>
      </ThemeModeContextProvider>
    </SbEditable>
  );
};

export default React.memo(AiPage);
