import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import { SpacingPreset } from './index';

export const Wrapper = styled.div<{ preset: SpacingPreset }>`
  background: ${({ theme }) => theme.bgPrimary};
  display: flex;
  flex-direction: column;

  row-gap: ${({ preset }) => preset.mobile.rowGap};
  padding-top: ${({ preset }) => preset.mobile.paddingTop};
  padding-bottom: ${({ preset }) => preset.mobile.paddingBottom};

  @media ${breakpointUp.tablet} {
    row-gap: ${({ preset }) => preset.tablet.rowGap};
    padding-top: ${({ preset }) => preset.tablet.paddingTop};
    padding-bottom: ${({ preset }) => preset.tablet.paddingBottom};
  }

  @media ${breakpointUp.desktop} {
    row-gap: ${({ preset }) => preset.desktop.rowGap};
    padding-top: ${({ preset }) => preset.desktop.paddingTop};
    padding-bottom: ${({ preset }) => preset.desktop.paddingBottom};
  }

  @media ${breakpointUp.desktopLarge} {
    row-gap: ${({ preset }) => preset.desktopLarge.rowGap};
    padding-top: ${({ preset }) => preset.desktopLarge.paddingTop};
    padding-bottom: ${({ preset }) => preset.desktopLarge.paddingBottom};
  }
`;
