import React from 'react';
import { Wrapper } from './SpaceWrapper.styles';
import { spacing } from '../../../styles/newThemeVariables.styles';

export type BreakpointSpacing = {
  rowGap?: string;
  paddingTop?: string;
  paddingBottom?: string;
};

export type SpacingPreset = {
  mobile: BreakpointSpacing;
  tablet: BreakpointSpacing;
  desktop: BreakpointSpacing;
  desktopLarge: BreakpointSpacing;
};

export interface SpaceWrapperProps {
  variant?: keyof typeof spacingPresets;
  customSpacing?: Partial<SpacingPreset>;
}

const spacingPresets = {
  default: {
    mobile: {
      rowGap: spacing.space144,
      paddingTop: spacing.space0,
      paddingBottom: spacing.space0,
    },
    tablet: {
      rowGap: spacing.space144,
      paddingTop: spacing.space0,
      paddingBottom: spacing.space0,
    },
    desktop: {
      rowGap: spacing.space200,
      paddingTop: spacing.space0,
      paddingBottom: spacing.space0,
    },
    desktopLarge: {
      rowGap: spacing.space200,
      paddingTop: spacing.space0,
      paddingBottom: spacing.space0,
    },
  },
};

const SpaceWrapper: React.FC<SpaceWrapperProps> = ({
  children,
  variant = 'default',
  customSpacing,
}) => {
  const preset: SpacingPreset = {
    mobile: {
      ...spacingPresets[variant].mobile,
      ...customSpacing?.mobile,
    },
    tablet: {
      ...spacingPresets[variant].tablet,
      ...customSpacing?.tablet,
    },
    desktop: {
      ...spacingPresets[variant].desktop,
      ...customSpacing?.desktop,
    },
    desktopLarge: {
      ...spacingPresets[variant].desktopLarge,
      ...customSpacing?.desktopLarge,
    },
  };

  return <Wrapper preset={preset}>{children}</Wrapper>;
};

export default SpaceWrapper;
