import React, { FC } from 'react';
import {
  Center,
  Description,
  EyebrowText,
  Title,
  CTALink,
} from './CallOut.styles';

export interface TextBlokProps {
  eyebrow?: string;
  title?: string;
  description?: React.ReactNode;
  ctaText?: string;
  ctaLink?: string;
  opacity?: number;
  textColor?: 'textPrimary' | 'textPrimaryInverse' | 'text400' | 'text500';
  textWrap?: 'wrap' | ' balance' | 'pretty';
}

const TextBlock: FC<TextBlokProps> = ({
  eyebrow,
  title,
  description,
  ctaText,
  ctaLink,
  opacity = 0.1,
  textColor = 'textPrimary',
  textWrap = 'pretty',
}) => {
  return (
    <Center $opacity={opacity} $textColor={textColor}>
      {eyebrow && <EyebrowText>{eyebrow}</EyebrowText>}
      {title && <Title $textWrap={textWrap}>{title}</Title>}
      {description && <Description>{description}</Description>}
      {ctaText && (
        <CTALink size={'md'} href={ctaLink}>
          {ctaText}
        </CTALink>
      )}
    </Center>
  );
};

export default TextBlock;
