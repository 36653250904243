import styled from 'styled-components';
import { typeScale } from '../../../styles/newTypography';
import { breakpointUp } from '../../../styles/mediaUtils';
import { spacing } from '../../../styles/newThemeVariables.styles';
import { Section } from '../../layout/Section';
import { StyledLinkButton } from '../../components/CallToActionLink/CallToActionLink.styles';
import { Icon } from '../../../icons/TickIcon';

export const FormSection = styled(Section)`
  background: ${({ theme }) => theme.bgSecondary};
  padding-top: ${spacing.space72};
  padding-bottom: ${spacing.space72};

  @media ${breakpointUp.tablet} {
    padding-top: ${spacing.space64};
    padding-bottom: ${spacing.space64};
  }

  @media ${breakpointUp.desktop} {
    padding-top: ${spacing.space112};
    padding-bottom: ${spacing.space88};
  }
`;

export const TextBox = styled.div`
  grid-column: 1 / -1;
  padding-bottom: ${spacing.space56};

  @media ${breakpointUp.tablet} {
    grid-column: 1 / span 5;
    padding-right: ${spacing.space48};
    padding-bottom: 0;
  }

  @media ${breakpointUp.desktop} {
    grid-column: 1 / span 4;
    padding-right: 0;
  }
`;

export const Tagline = styled.div`
  ${typeScale.mobile.mono.md}
  margin-bottom: ${spacing.space16};
  color: ${({ theme }) => theme.text200};
  grid-column: 1 / -1;

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.mono.md}
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.mono.md}
  }
`;

export const Title = styled.h2`
  ${typeScale.mobile.body.xl}
  margin-bottom: ${spacing.space16};

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.body.xl}
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.xxxl}
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.xxxxl}
  }
`;

export const Subtitle = styled.div`
  ${typeScale.mobile.body.sm}
  color: ${({ theme }) => theme.text400};

  p:has(img:only-child) {
    margin-top: ${spacing.space56};

    @media ${breakpointUp.tablet} {
      margin-top: ${spacing.space48};
    }

    @media ${breakpointUp.desktop} {
      margin-top: ${spacing.space40};
    }

    @media ${breakpointUp.desktopLarge} {
      margin-top: ${spacing.space36};
    }
  }

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.body.sm}
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.md}
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.lg}
  }
`;

export const Form = styled.form`
  grid-column: 1 / -1;

  @media ${breakpointUp.tablet} {
    grid-column: 6 / span 6;
  }

  @media ${breakpointUp.desktop} {
    grid-column: 7 / span 5;
  }

  @media ${breakpointUp.desktopLarge} {
    grid-column: 7 / span 4;
  }

  ${StyledLinkButton} {
    ${Icon} {
      margin-left: ${spacing.space8};
    }
  }
`;
