import styled from 'styled-components';
import { typeScale } from '../../../styles/newTypography';
import { breakpointUp } from '../../../styles/mediaUtils';
import { spacing } from '../../../styles/newThemeVariables.styles';

export const QuoteText = styled.div`
  ${typeScale.mobile.body.xl}
  color: ${({ theme }) => theme.textPrimary};
  text-wrap: balance;

  &::before {
    content: '“';
  }

  &::after {
    content: '”';
  }

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.body.xxxl}
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.xxl}
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.xxxxl}
  }
`;

export const Author = styled.div`
  ${typeScale.mobile.body.xs}
  color: ${({ theme }) => theme.text400};
  padding-top: ${spacing.space24};

  @media ${breakpointUp.tablet} {
    ${typeScale.desktop.body.sm}
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.sm}
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.sm}
  }
`;

export const Blockquote = styled.blockquote`
  margin: 0;
  text-align: center;
`;
