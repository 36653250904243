import React from 'react';
import SbEditable, { SbEditableContent } from 'storyblok-react';
import {
  BlokComponent,
  NewStoryblokMetaData,
  StoryblokAsset,
  StoryblokAuthor,
  StoryblokCategories,
  StoryblokRelatedPosts,
  StoryblokTags,
} from '../../types/blok.model';
import defaultTheme, { darkTheme } from '../../styles/themeVariables';
import RenderAfterPageTransition from '../../old/components/RenderAfterPageTransition';
import styled, { ThemeProvider } from 'styled-components';
import { ThemeModeContextProvider } from '../../util/useThemeMode';

import { spacing } from '../../styles/newThemeVariables.styles';
import { useBlogPostThumbnails } from '../../util/useBlogPostThumbnails';
import { transformCategorySlugs } from '../../util/transformCollection';
import isEmpty from '../../util/isEmpty';

import CardList from '../modules/CardList';
import CardBlogContent from '../blocks/CardBlogContent';
import ContactFormModule from '../modules/ContactFormModule';
import ArticleContentModuleBlok from '../modules/ArticleContentModule/ArticleContentModule.blok';
import BlogHeaderBlok from '../modules/BlogHeaderModule/BlogHeader.blok';
import { RichTextDocument } from '../components/RichText/StoryblokRichText';
import BlogGalleryModuleBlok from '../modules/BlogHeroImage/BlogHeroImage.blok';
import { breakpointUp } from '../../styles/mediaUtils';
import { colors } from '../../styles/themeVariables';
import { Layout } from '../layout/Layout';
import { Grid } from '../layout/Grid';

interface BlogPostSchema extends NewStoryblokMetaData {
  title: string;
  body?: SbEditableContent[];
  bodyLower?: SbEditableContent[];
  author: StoryblokAuthor;
  categories: StoryblokCategories;
  tags: StoryblokTags;
  hero_image: StoryblokAsset;
  hero_lg: StoryblokAsset;
  thumbnail_image: StoryblokAsset;
  content: RichTextDocument;
  read_next: StoryblokRelatedPosts;
  hide_hero_image: boolean;
}

const BodyContent = styled.div`
  background-color: ${colors.snow};
`;

const StyledImageWrapper = styled.div`
  grid-column: full;
`;

const StyledArticleWrapper = styled.div`
  grid-column: 1 / -1;

  @media ${breakpointUp.md} {
    grid-column: 3 / span 6;
  }
`;

const Background = styled.div`
  background: ${({ theme }) => theme.bgPrimary};
`;

const ListContainer = styled.div`
  padding-top: ${spacing.space72};
  padding-bottom: ${spacing.space72};

  @media ${breakpointUp.tablet} {
    padding-top: ${spacing.space64};
    padding-bottom: ${spacing.space64};
  }

  @media ${breakpointUp.desktop} {
    padding-top: ${spacing.space112};
    padding-bottom: ${spacing.space112};
  }
`;

const NewBlogPost: BlokComponent<BlogPostSchema> = ({ blok, Dynamic }) => {
  const relatedPosts = transformCategorySlugs(
    useBlogPostThumbnails(blok?.read_next ?? [])
  );

  return (
    <SbEditable content={blok}>
      <BlogHeaderBlok
        Dynamic={Dynamic}
        blok={{
          _uid: '',
          component: '',
          author: blok.author,
          title: blok.title,
          categories: blok.categories,
          tags: blok.tags,
        }}
      />
      <BodyContent>
        <RenderAfterPageTransition>
          {blok.hero_image?.filename && !blok.hide_hero_image && (
            <Layout variant="smallWidth">
              <StyledImageWrapper>
                <BlogGalleryModuleBlok
                  Dynamic={Dynamic}
                  blok={{
                    image_sm: blok.hero_image,
                    image_lg: blok.hero_lg?.filename
                      ? blok.hero_lg
                      : blok.hero_image,
                    imageAlt:
                      blok.hero_image?.alt || `Hero Image for ${blok.title}`,
                    _uid: '',
                    component: '',
                  }}
                />
              </StyledImageWrapper>
            </Layout>
          )}
          <Layout variant="smallWidth">
            <Grid>
              <StyledArticleWrapper>
                {blok.content && (
                  <>
                    <ArticleContentModuleBlok
                      blok={{
                        _uid: '',
                        component: '',
                        content: blok.content,
                      }}
                      Dynamic={Dynamic}
                    />
                  </>
                )}
              </StyledArticleWrapper>
            </Grid>
          </Layout>
        </RenderAfterPageTransition>
      </BodyContent>

      <ThemeModeContextProvider themeMode={'light'}>
        <ThemeProvider theme={defaultTheme}>
          <Background>
            <RenderAfterPageTransition>
              {blok.body?.map((insideBlok) => (
                <Dynamic key={insideBlok._uid} blok={insideBlok} />
              ))}
            </RenderAfterPageTransition>
          </Background>
        </ThemeProvider>
      </ThemeModeContextProvider>

      <ThemeModeContextProvider themeMode={'dark'}>
        <ThemeProvider theme={darkTheme(defaultTheme)}>
          <Background>
            <RenderAfterPageTransition>
              {blok.bodyLower?.map((insideBlok) => (
                <Dynamic key={insideBlok._uid} blok={insideBlok} />
              ))}
              {relatedPosts && !isEmpty(relatedPosts) && (
                // <BlogRelatedPosts posts={relatedPosts} />
                <ListContainer>
                  <CardList
                    scrollMobile={false}
                    title="Related Posts"
                    ctaText="See All"
                    ctaLink="/blog/"
                    mobile={{
                      rowGap: spacing.space64,
                      variant: 'one-column',
                    }}
                    tablet={{
                      rowGap: spacing.space64,
                      variant: 'one-column',
                    }}
                    desktop={{
                      rowGap: spacing.space112,
                      variant: 'two-column',
                    }}
                    desktopLarge={{
                      rowGap: spacing.space112,
                      variant: 'two-column',
                    }}
                  >
                    {relatedPosts.map((blogPost) => (
                      <CardBlogContent
                        key={blogPost.id}
                        title={blogPost.title}
                        link={blogPost.link}
                        mobile={{
                          aspect: '3 / 4',
                          thumbnail: blogPost.thumbnail_3x4,
                        }}
                        tablet={{
                          aspect: '4 / 3',
                          thumbnail: blogPost.thumbnail_4x3,
                        }}
                        desktop={{
                          aspect: '4 / 3',
                          thumbnail: blogPost.thumbnail_4x3,
                        }}
                        desktopLarge={{
                          aspect: '4 / 3',
                          thumbnail: blogPost.thumbnail_4x3,
                        }}
                        thumbnailAlt={blogPost.thumbnail_alt}
                        description={blogPost.description}
                        tags={[...blogPost.tags, ...blogPost.categories]}
                      />
                    ))}
                  </CardList>
                </ListContainer>
              )}
              <ContactFormModule
                form_data={'7dfec556-192c-483f-bc39-246e44bb1f7b'}
                tagline={'READY TO GET STARTED?'}
                title={'Transform how your most important work is delivered'}
                subtitle={
                  'Meet the team, discuss your ideas with our experts and receive a proposal for your project.'
                }
              />
            </RenderAfterPageTransition>
          </Background>
        </ThemeProvider>
      </ThemeModeContextProvider>
    </SbEditable>
  );
};

export default React.memo(NewBlogPost);
