import { Text, InlineType } from '../new/components/RichText/InlineRenderer';
import { ContentBit } from '../new/components/RichText/BlockRenderer';
import { PageContextProps } from '../storyblok/entry';

type ContentNode = {
  type: string;
  content?: ContentNode[];
  [key: string]: unknown;
};

const DEFAULT_PHRASES = new Set(['Generative AI', 'Generative UI']);
const DEFAULT_LINK_LIMIT = 1;

/**
 * Escapes special characters in a string for use in a RegExp.
 * @param string - The input string to escape.
 * @returns The escaped string.
 */
const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

/**
 * Creates compound words by replacing spaces with non-breaking spaces
 * in specified phrases within a content string, ignoring case.
 * @param content - The input content string.
 * @param phrases - A Set of phrases to process.
 * @returns The processed content with non-breaking spaces in specified phrases.
 */
const createCompoundWords = (content: string, phrases = DEFAULT_PHRASES) => {
  const nonBreakingSpace = '\u00A0';
  let result = content;

  for (const phrase of phrases) {
    const regex = new RegExp(`\\b(${escapeRegExp(phrase)})\\b`, 'gi');
    result = result?.replace(regex, (match) =>
      match?.replace(/ /g, nonBreakingSpace)
    );
  }

  return result;
};

/**
 * Adds auto-links to text nodes within paragraphs in Storyblok rich text content.
 * Only updates text nodes within 'paragraph' nodes, and skips headings and other node types.
 * Skips self-linking and nodes that already contain links.
 *
 * @param content - The Storyblok rich text content as a JSON string
 * @param linksList - List of auto-link phrases and slugs to be applied
 * @param contentPath - slug for page being processed
 * @param linkLimit - number of links per phrase to create (if multiple instances are found)
 * @returns Transformed Storyblok content string with links applied
 */
const createAutoLinks = (
  content: string,
  linksList: PageContextProps['autoLinks'] = { nodes: [] },
  contentPath?: string,
  linkLimit = DEFAULT_LINK_LIMIT
): string => {
  const linkCounts: Record<string, number> = {};

  /**
   * Adds links to a text node if it matches phrases in the linksList
   * @param textNode - The text node to process
   * @param phrase - Phrase to match within the text
   * @param slug - The URL to link the matched phrase to
   * @returns The updated text node with links applied
   */
  const addLinksToText = (
    textNode: Text,
    phrase: string,
    slug: string
  ): Text | Text[] => {
    if (slug === contentPath) return textNode;

    const regex = new RegExp(`\\b(${escapeRegExp(phrase)})\\b`, 'gi');
    if (!regex.test(textNode.text)) return textNode;

    const parts = textNode.text.split(regex);
    return parts.map((part) => {
      const hasPhrase = part.toLowerCase() === phrase.toLowerCase();

      if (hasPhrase) {
        linkCounts[phrase] = (linkCounts[phrase] || 0) + 1;
        if (linkCounts[phrase] <= linkLimit) {
          return {
            type: 'text',
            text: part,
            marks: [
              ...(textNode.marks || []),
              {
                type: 'link',
                attrs: {
                  href: slug,
                  linktype: 'url',
                },
              },
            ],
          };
        }
      }

      return {
        ...textNode,
        text: part,
      };
    });
  };

  /**
   * Processes a single node, applying auto-links to text nodes within paragraph nodes.
   * @param node - The node to process
   * @param parentType - The type of the parent node (e.g., 'paragraph', 'heading')
   * @returns The transformed node
   */
  const processNode = (
    node: InlineType | ContentBit,
    parentType?: string
  ): InlineType | ContentBit => {
    if (node.type !== 'text') {
      return node;
    }

    const isLinkAlready = node.marks?.some((mark) => mark.type === 'link');
    if (isLinkAlready) {
      return node;
    }

    if (node.text && parentType === 'paragraph') {
      let processedNode: Text | Text[] = node;

      linksList?.nodes?.forEach((link) => {
        if (Array.isArray(processedNode)) {
          processedNode = processedNode.flatMap((subNode) =>
            addLinksToText(subNode, link.name, link.value)
          );
        } else {
          processedNode = addLinksToText(processedNode, link.name, link.value);
        }
      });

      return processedNode;
    }

    return node;
  };

  /**
   * Recursively processes content nodes and their children, maintaining parent node types.
   * @param item - The content item to process (can be an object or array)
   * @param parentType - The type of the parent node
   * @returns The processed content item
   */
  const processContent = (
    item: ContentNode | ContentNode[],
    parentType?: string
  ): ContentNode | ContentNode[] => {
    if (Array.isArray(item)) {
      return item.flatMap((child) => processContent(child, parentType));
    }

    if (typeof item === 'object' && item !== null) {
      const newItem: ContentNode = { ...item };

      for (const [key, value] of Object.entries(item)) {
        newItem[key] =
          typeof value === 'object' && value !== null
            ? processContent(value as ContentNode, item.type)
            : value;
      }

      return processNode(newItem as ContentBit | InlineType, parentType);
    }
    return item;
  };

  const parsedContent = JSON.parse(content);
  const processedContent = processContent(parsedContent);
  return JSON.stringify(processedContent);
};

export {
  createAutoLinks,
  createCompoundWords,
  DEFAULT_PHRASES,
  DEFAULT_LINK_LIMIT,
};
