import React from 'react';
import SbEditable from 'storyblok-react';
import { BlokComponent, StoryblokColor } from '../../../types/blok.model';
import ContactFormModule, { ContactFormModuleProps } from '.';
import { Colors } from '../../components/SectionWrapper';
import StoryblokRichText, {
  RichTextDocument,
} from '../../components/RichText/StoryblokRichText';

type ContactFormModuleSchema = ContactFormModuleProps &
  Colors<StoryblokColor> & { subtitle: string | RichTextDocument };

export const ContactFormModuleBlok: BlokComponent<ContactFormModuleSchema> = ({
  blok,
  Dynamic,
}) => {
  const props = {
    ...blok,
  } as ContactFormModuleProps;

  const body =
    typeof blok.subtitle !== 'string' ? (
      <StoryblokRichText content={blok.subtitle} Dynamic={Dynamic} />
    ) : (
      blok.subtitle
    );

  return (
    <SbEditable content={blok}>
      <ContactFormModule {...props} subtitle={body} />
    </SbEditable>
  );
};

export default ContactFormModuleBlok;
