import styled from 'styled-components';
import { typeScale } from '../../../styles/newTypography';
import { breakpointUp } from '../../../styles/mediaUtils';
import { spacing } from '../../../styles/newThemeVariables.styles';

export const EyebrowText = styled.div`
  ${typeScale.mobile.mono.md}
  color: ${({ theme }) => theme.text200};
  margin-bottom: ${spacing.space24};

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.mono.md}
    margin-bottom: ${spacing.space16};
  }
`;
