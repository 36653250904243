import React from 'react';
import SbEditable from 'storyblok-react';
import CardBlogContent, { CardBlogContentProps } from '.';
import { BlokComponent } from '../../../types/blok.model';
import { useBlogPostThumbnails } from '../../../util/useBlogPostThumbnails';
import { useAuthors } from '../../../old/storyblok/authors-context';

export interface CardBlogContentBlokProps extends CardBlogContentProps {
  contentId: string;
  hideDescription?: boolean;
  hideMetaUpper?: boolean;
  hideMetaLower?: boolean;
}

const CardBlogContentBlok: BlokComponent<CardBlogContentBlokProps> = ({
  blok,
}) => {
  const blogPost = useBlogPostThumbnails([blok.contentId])?.[0];
  const authors = useAuthors();
  const author = authors.find((item) => item.uuid === blogPost?.author);

  if (!blogPost) {
    return null;
  }

  const thumbnailProps = {
    mobile: { aspect: '3 / 4', thumbnail: blogPost.thumbnail_3x4 },
    tablet: { aspect: '16 / 9', thumbnail: blogPost.thumbnail_4x3 },
    desktop: { aspect: '3 / 4', thumbnail: blogPost.thumbnail_4x3 },
    desktopLarge: { aspect: '3 / 4', thumbnail: blogPost.thumbnail_3x4 },
  };

  return (
    <SbEditable content={blok}>
      <CardBlogContent
        {...blok}
        {...thumbnailProps}
        title={blogPost.title}
        link={blogPost.link}
        thumbnailAlt={blogPost.thumbnail_alt}
        author={blok.hideMetaUpper ? undefined : author}
        description={blok.hideDescription ? undefined : blogPost.description}
        tags={
          blok.hideMetaLower
            ? undefined
            : [...blogPost.tags, ...blogPost.categories]
        }
      />
    </SbEditable>
  );
};

export default CardBlogContentBlok;
